<template>
  <div>
    <b-nav tabs>
      <b-nav-item :to="{ name: 'building' }" :active="$route.name == 'building'"
        >Características</b-nav-item
      >
      <b-nav-item :to="{ name: 'energy' }" :active="$route.name == 'energy'"
        >Instalación</b-nav-item
      >
      <b-nav-item :to="{ name: 'score' }" :active="$route.name == 'score'"
        >Calificación</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'improvements' }"
        :active="$route.name == 'improvements'"
        >Mejoras</b-nav-item
      >
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "BuildingNavBar",
};
</script>

<style></style>
