<template>
  <section>
    <BuildingNavBar />
    <div class="section">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import BuildingNavBar from "../components/BuildingNavBar";
// import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
export default {
  name: "CreateBuilding",

  components: { BuildingNavBar },
};
</script>

<style lang="scss" scoped>
.section {
  margin: 30px;
}
</style>
